import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Terms from 'components/Terms'

const CodeOfConduct = () => {
  return (
    <Layout>
      <SEO title="Code of Conduct" />
      <Terms name="CODE_OF_CONDUCT" />
    </Layout>
  )
}

export default CodeOfConduct
